<template>
    <div class="user-index">
        <div class="main-h1">Просмотр дела</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">Дело</div>
                <div class="row">
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Номер дела *</div>
                            <input v-model="claim.title"/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Тип *</div>
                            <select v-model="claim.type_id">
                                <option v-for="option in types" :value="option.id">{{ option.title }}</option>
                            </select>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Город *</div>
                            <select v-model="claim.city_id">
                                <option v-for="option in cities" :value="option.id">{{ option.title }}</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Участники</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Истец ({{ claim.claimant_type }})</div>
                            <!--
                            <select v-model="claim.claimant_id">
                                <option v-for="option in users.claimants" :value="option.id" :selected="option.id == claim.claimant_id">{{option.name}}</option>
                            </select>
                            -->
                            <Select2 v-model="claim.claimant_id"
                                     :options="users.claimants.map(item => {item.text = item.name; return item;})"
                                     :settings="{width: '100%'}"/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Арбитр</div>
                            <Select2 v-model="claim.arbiter_id"
                                     :options="users.arbiters.map(item => {item.text = item.name; return item;})"
                                     :settings="{width: '100%'}"/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Ответчик ({{ claim.defendant_type }})</div>
                            <Select2 v-model="claim.defendant_id"
                                     :options="users.defendants.map(item => {item.text = item.name; return item;})"
                                     :settings="{width: '100%'}"/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Агент</div>
                            <Select2 v-model="claim.agent_id"
                                     :options="users.agents.map(item => {item.text = item.name; return item;})"
                                     :settings="{width: '100%'}"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Стадия</div>
                <div class="row">
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Стадия</div>
                            <select v-model="claim.stage_id">
                                <option v-for="option in stages" :value="option.id"
                                        :selected="option.id == claim.stage_id">{{ option.title }}
                                </option>
                            </select>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Дедлайн стадии</div>
                            <input v-model="claim.stage_dl" type="date"/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Ответственный</div>
                            <select v-model="claim.implementer_id">
                                <option v-for="option in users.implementers" :value="option.id"
                                        :selected="option.id == claim.implementer_id">{{ option.name }}
                                </option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Даты</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Иск принят</div>
                            <input v-model="claim.receipt_date" type="date" disabled/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Рассмотрение</div>
                            <input v-model="claim.consideration_date" type="date" :min="claim.receipt_date"/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Вынесено решение</div>
                            <input v-model="claim.decision_date" type="date" :min="claim.consideration_date"/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Выдан исполнительный лист</div>
                            <input v-model="claim.writ_date" type="date" :min="claim.decision_date"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Суммы</div>
                <div class="row">
                    <div class="col-fg-1">
                        <label class="form-label">
                            <div class="t">Сумма иска</div>
                            <input v-model="claim.amount" type="number" min="0"/>
                        </label>
                    </div>
                    <div class="col-fg-1" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Арбитражный сбор</div>
                            <input v-model="claim.amount_sbor" type="number" min="0"/>
                        </label>
                    </div>
                    <div class="col-fg-1" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Возврат арбитражного сбора</div>
                            <input v-model="claim.amount_sbor_out" type="number" min="0"/>
                        </label>
                    </div>
                    <div class="col-fg-1" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Гонорар арбитра</div>
                            <input v-model="claim.amount_arbiter" type="number" min="0"/>
                        </label>
                    </div>
                    <div class="col-fg-1" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Вознаграждение агента</div>
                            <input v-model="claim.amount_agent" type="number" min="0"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Документы</div>
                <file-list :list="files.filter(item => item.type_id == 1)" @updated="updateFiles"/>
                <upload-file doc_type="1" parent_type="claim" :parent_id="claim.id" @uploaded="updateFiles"/>
            </div>
            <notification-list v-if="notifications.length" :list="notifications" @updated="updateNotification"/>
            <notification-create parent_type="claim" :parent_id="claim.id" @uploaded="updateNotification"/>
            <div class="card-body">
                <div class="card-body-title">Записи процессов</div>
                <file-list :list="files.filter(item => item.type_id == 3)" @updated="updateFiles"/>
                <upload-file doc_type="3" parent_type="claim" :parent_id="claim.id" @uploaded="updateFiles"/>
            </div>
            <div class="card-body">
                <div class="card-body-title">Решение</div>
                <file-list :list="files.filter(item => item.type_id == 4)" @updated="updateFiles"/>
                <upload-file doc_type="4" parent_type="claim" :parent_id="claim.id" @uploaded="updateFiles"/>
            </div>
            <!--
            <payment-list v-if="payments.length" :list="payments" @updated="updatePayments"/>
            <payment-create :parent_id="claim.id" @uploaded="updatePayments" @updated="updatePayments"/>
            -->
            <div class="card-body" v-if="claim.appeals">
                <div class="card-body-title">Обращения</div>
                <appeal-list :list="claim.appeals"/>
            </div>
            <div class="card-body">
                <div class="card-body-title">Связанные иски</div>
                <claim-list v-if="connected.length" :list="connected"/>
                <div class="row">
                    <div class="col-3"><input v-model="newConnected" type="number" placeholder="id дела"></div>
                    <div class="col-3">
                        <div class="btn blue" @click="claimConnect">Привязать</div>
                    </div>
                    <div class="col-6"></div>
                </div>
            </div>
            <div class="savebtn btn blue inb mb-20" @click="saveClaim">Сохранить</div>
            <div class="card-body" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isArbiter()">
                <div class="card-body-title">История</div>
                <button class="btn blue" @click="logGet" v-if="!log.length">Получить</button>
                <table class="table-log" v-if="log.length">
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Автор</th>
                        <th>Изменение</th>
                    </tr>
                    </thead>
                    <tr v-for="item in log">
                        <td>{{ item.date }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.text }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import notificationCreate from "../../../components/notification/create";
import notificationList from "../../../components/notification/list";
import uploadFile from "../../../components/file/upload";
import fileList from "../../../components/file/list";
//import paymentList from "../../../components/payment/list"
//import paymentCreate from "../../../components/payment/create"
import claimList from '../../../components/claim/list.vue';
import appealList from '../../../components/appeal/list'
import Select2 from 'vue3-select2-component';

export default {
    name: "edit",
    components: {
        uploadFile,
        fileList,
        notificationCreate,
        notificationList,
        claimList,
        appealList,
        Select2
    },
    data() {
        return {
            claim: {},
            users: {
                claimants: [],
                arbiters: [],
                agents: [],
                defendants: [],
                implementers: [],
            },
            stages: [],
            cities: [],
            types: [],
            log: [],
            files: [],
            notifications: [],
            //payments: [],
            connected: [],
            newConnected: false,
        }
    },
    methods: {
        claimConnect() {
            if (this.newConnected) {
                this.$api.post(`claim/${this.$route.params.id}/connect`, {new: this.newConnected}).then(res => {
                    if (res.data.success) {
                        this.$store.commit('showNotify', {
                            title: 'Сохранено',
                            content: 'Дело успешно привязано.',
                            type: 'success'
                        });
                        this.connected = [];
                        this.getClaim();
                    } else {
                        this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                    }
                })
            }
        },
        updateFiles() {
            this.getFiles();
            //this.logGet();
        },
        updateNotification() {
            this.getNotifications();
            //this.logGet();
        },
        updatePayments() {
            //this.getPayments();
            //this.logGet();
        },
        getClaimConnected(claimId) {
            this.$api.get(`claim/${claimId}`).then(res => {
                if (res.data.success) {
                    this.connected.push(res.data.data)
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getClaim() {
            this.$api.get(`claim/${this.$route.params.id}`).then(res => {
                if (res.data.success) {
                    this.claim = res.data.data;
                    //this.logGet();
                    if (res.data.data.connected.length) {
                        res.data.data.connected.forEach(claimId => {
                            this.getClaimConnected(claimId)
                        })
                    }
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        saveClaim() {
            this.$api.post(`claim/${this.$route.params.id}`, this.claim).then(res => {
                if (res.data.success) {
                    this.claim = res.data.data;
                    this.$store.commit('showNotify', {
                        title: 'Сохранено',
                        content: 'Данные успешно сохранены.',
                        type: 'success'
                    });
                    //this.logGet();
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        logGet() {
            this.$api.get(`claim/${this.$route.params.id}/log`).then(res => {
                if (res.data.success) {
                    this.log = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getImplementers() {
            this.$api.get(`user/implementers`).then(res => {
                if (res.data.success) {
                    this.users.implementers = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getClaimants() {
            this.$api.get(`user/claimants`).then(res => {
                if (res.data.success) {
                    this.users.claimants = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getArbiters() {
            this.$api.get(`user/arbiters`).then(res => {
                if (res.data.success) {
                    this.users.arbiters = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getDefendants() {
            this.$api.get(`user/defendants`).then(res => {
                if (res.data.success) {
                    this.users.defendants = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getAgents() {
            this.$api.get(`user/agents`).then(res => {
                if (res.data.success) {
                    this.users.agents = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getStages() {
            this.$api.get(`claim/stages`).then(res => {
                if (res.data.success) {
                    this.stages = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getTypes() {
            this.$api.get(`claim/types`).then(res => {
                if (res.data.success) {
                    this.types = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getFiles() {
            this.$api.get(`claim/${this.$route.params.id}/file`).then(res => {
                if (res.data.success) {
                    this.files = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getNotifications() {
            this.$api.get(`claim/${this.$route.params.id}/notification`).then(res => {
                if (res.data.success) {
                    this.notifications = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getPayments() {
            this.$api.get(`claim/${this.$route.params.id}/payment`).then(res => {
                if (res.data.success) {
                    this.payments = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        }
    },
    created() {
        this.getClaim();
        this.getCities();
        this.getTypes();
        this.getImplementers();
        this.getClaimants();
        this.getArbiters();
        this.getDefendants();
        this.getStages();
        this.getAgents();
        this.getFiles();
        this.getNotifications();
        //this.getPayments();
    },
    watch: {
        '$route.params.id': function () {
            if (this.$route.params.id) {
                this.connected = [];
                this.files = [];
                this.notifications = [];
                this.getClaim();
                this.getImplementers();
                this.getClaimants();
                this.getArbiters();
                this.getDefendants();
                this.getStages();
                this.getAgents();
                this.getFiles();
                this.getNotifications();
                //this.getPayments();
            }
        },
        claim: {
            handler(newVal, oldVal) {
                if (oldVal.id) {
                    let btn = document.querySelector('.savebtn');
                    btn.classList.add('fixed');
                    setTimeout(() => {
                        btn.classList.add('show');
                    }, 100)
                }
            },
            deep: true,
        },
        'claim.amount':function (){
            switch (this.claim.type_id) {
                case 1: // Имущественный
                    switch (true) {
                        case this.claim.amount <= 1600000:
                            this.claim.amount_sbor = Math.max(Math.ceil(this.claim.amount * 0.06), 100000);
                            break;
                        case this.claim.amount > 1600000 && this.claim.amount <= 5000000:
                            this.claim.amount_sbor = Math.max(Math.ceil(this.claim.amount * 0.05), 100000);
                            break;
                        case this.claim.amount > 5000000 && this.claim.amount <= 10000000:
                            this.claim.amount_sbor = Math.ceil(this.claim.amount * 0.04);
                            break;
                        case this.claim.amount > 10000000:
                            this.claim.amount_sbor = Math.ceil(this.claim.amount * 0.03);
                            break;
                    }
                    this.claim.amount_arbiter = Math.min(Math.ceil(this.claim.amount_sbor * 0.1), 150000);
                    this.claim.amount_agent = 0;
                    if (this.claim.agent_id > 0) {
                        this.claim.amount_agent = Math.ceil(this.claim.amount_sbor * 0.1);
                    }
                    break;
                case 2: // Неимущественный
                    this.claim.amount = 0;
                    this.claim.amount_sbor = 200000;
                    this.claim.amount_arbiter = 20000;
                    this.claim.amount_agent = 0;
                    if (this.claim.agent_id > 0) {
                        this.claim.amount_agent = 20000;
                    }
                    break;
            }
        }
    }

}
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";

.savebtn {
    &.fixed {
        position: fixed;
        bottom: 20px;
        left: -295px;
        transition: 0.2s;
        box-shadow: 0 0 5px $cl-blue;

        &.show {
            left: 295px;
        }
    }
}

.amount-sbor-wrap {
    display: flex;
    gap:10px;
}
</style>